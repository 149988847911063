import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { AnimationContext } from 'context/animation';
import { MOBILE_MEDIA_POINT } from 'utils/constant';
import { MenuTrigger } from 'components/MenuTrigger';

import { ReactComponent as DotsImage } from 'i/circle.svg';
import { JsonContext } from 'context/json';

const Footer = React.forwardRef(({ }, ref) => {
	const { jsonData } = useContext(JsonContext);
	const { windowWidth, changeAppTheme } = useContext(AnimationContext);
	const { companyLocation, copyright } = jsonData;
	let location = useLocation();

	return (
		<footer className="footer" ref={ref}>
			<div className="footer_overlay"></div>
			<div className="section_in footer_mod">
				<MenuTrigger />
				{windowWidth > MOBILE_MEDIA_POINT && <div className="footer_copyright">{copyright}</div>}
				{(location.pathname === '/design' || location.pathname === '/contact') && windowWidth > MOBILE_MEDIA_POINT ? (
					<div className="footer_adress">{companyLocation}</div>
				) : false}
				<div
					className="footer_decor"
					onClick={() => changeAppTheme()}
				>
					<DotsImage className="footer_decor_in" />
				</div>
			</div>
		</footer>
	);
});

export default Footer;