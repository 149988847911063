import React, { useContext } from 'react';

import { JsonContext } from 'context/json';

const CategoryList = ({ selectProjectCategory, currentProjectCategory }) => {
	const { jsonData, setJsonData } = useContext(JsonContext);

	return (
		<ul className="category_list">
			{jsonData.homePage.categoryNav.map(({ title }) => {
				return (
					<li className="category_item" key={title}>
						<button
							className={`category_link ${currentProjectCategory && currentProjectCategory === title.toLowerCase() ? 'active_mod' : ''}`}
							onClick={selectProjectCategory}
							data-category={title}
						>{title}</button>
					</li>
				)
			})}
		</ul>
	);

};

export default CategoryList;