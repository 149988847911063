import React, { useContext } from 'react';
import classNames from 'classnames';
import { useHistory } from "react-router-dom";

import { AnimationContext } from 'context/animation';
import { JsonContext } from 'context/json';

const ProjectMenuBlock = React.forwardRef(({ v1Mod, v2Mod }, ref) => {
	const { jsonData } = useContext(JsonContext);
	const { setMainMenuOpen } = useContext(AnimationContext);
	const history = useHistory();

	const PROJECTS_LINKS = jsonData.homePage.projectsLinks;
	const projectMenuClasses = classNames('project_menu_block', {
		v1_mod: v1Mod,
		v2_mod: v2Mod,
	});

	const handleOnClick = projectSlug => {
		setMainMenuOpen(false);
		history.push(`/projects/${projectSlug}`);
	};

	return (
		<div className={projectMenuClasses} ref={ref}>
			<ul className="project_menu_list">
				{PROJECTS_LINKS.map(({ title, slug }) => {
					return (
						<li className="project_menu_list_item" key={title}>
							<a className="project_menu_list_link" onClick={() => handleOnClick(slug)}>{title}</a>
						</li>
					)
				})}
			</ul>
		</div>
	);
});

export default ProjectMenuBlock;