import React, { useEffect, useContext, useRef } from 'react';
import { gsap, Power3 } from "gsap";

import { AnimationContext } from 'context/animation';
import { MOBILE_MEDIA_POINT } from 'utils/constant';
import { JsonContext } from 'context/json';

const FooterAdress = () => {
	const { jsonData } = useContext(JsonContext);
  const { windowWidth } = useContext(AnimationContext);
  const footerAdressEl = useRef(null);
  const footerCopyrightEl = useRef(null);
  const { companyLocation, copyright } = jsonData;

  useEffect(() => {
    if (windowWidth <= MOBILE_MEDIA_POINT) {
			gsap.from([footerAdressEl.current, footerCopyrightEl.current], {
				yPercent: 100,
				duration: 1.2,
				stagger: .1,
				delay: .4,
				ease: Power3.easeOut
			})
		}
  }, []);

  return (
    <div className="footer_w_adress">
      <div className="footer_adress">
        <div className="footer_adress_in" ref={footerAdressEl}>{companyLocation}</div>
      </div>
      <div className="footer_copyright">
        <div className="footer_copyright_in" ref={footerCopyrightEl}>{copyright}</div>
      </div>
    </div>
  );
};

export default FooterAdress;