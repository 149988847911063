import React, {
	useEffect,
	useRef,
	useState,
	useContext
} from 'react';

import { useHistory } from "react-router-dom";
import { AnimationContext } from 'context/animation';

import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { MOBILE_MEDIA_POINT, TABLET_MEDIA_POINT } from 'utils/constant';
import { JsonContext } from 'context/json';
import ProjectImage from 'components/Project/ProjectImage';
import BtnBack from 'components/BtnBack/BtnBack';
import { Logo } from 'components/Logo';

gsap.registerPlugin(ScrollTrigger);

const ProjectsPage = (props) => {
	const projectPreview = useRef(null);
	const sectionWrap = useRef(null);
	const sectionContentEl = useRef(null);
	const projectMainImageEl = useRef(null);
	const projectMainImageInEl = useRef(null);
	const projectDescr = useRef(null);
	const [projectPreviewElSize, setProjectPreviewElSize] = useState(null);
	const { windowHeight, windowWidth, isProjectPageAnimDisabled, prevLocation } = useContext(AnimationContext);
	const { jsonData } = useContext(JsonContext);
	const logoEl = useRef(null);
	const {
		projectName,
		projectDescription,
		mainProjectImage,
		firstProjectImage,
		projectImages,
	} = jsonData.projectsPage[props.match.params.name];

	let history = useHistory();
	// let isTransitionToPage = false;
	let scrollTop = 0;
	let pastScrollTop = false;
	let skewDirection = 2;
	let isDirectionUp = false;
	let isDirectionDown = false;
	let isNoScrolling = true;
	// let startTouchY;
	// let isTouch = false;
	const SKEW_ANIM_EASING = "power3.out";

	useEffect(() => {
			let $sections = document.querySelectorAll('.project_w_block');
			let preparedWindowHeight = window.innerHeight;

			$sections.forEach(($section, i) => {
				ScrollTrigger.batch($section, {
					scroller: '.section_wrap',
					start: window.innerWidth <= MOBILE_MEDIA_POINT ? ("-=" + preparedWindowHeight / 1.3) : "top center",
					onEnter: batch => tlProjectImage.play()
				});

				let tlProjectImage = gsap.timeline({
					paused: true,
				});

				let $sectionImageWrap = $section.querySelectorAll('.project_block_img_wrap');
				let $sectionImageWrapIn = $section.querySelectorAll('.project_block_img_wrap_in');
				let $sectionTitle = $section.querySelector('.project_title_w_in');
				let $sectionDescr = $section.querySelector('.project_text');

				tlProjectImage
					.addLabel('anim')
					.fromTo($sectionImageWrap, {
						opacity: 0
					}, {
						opacity: 1,
						duration: .8,
						ease: "power2.out",
					})
					.fromTo($sectionImageWrap, {
						y: 90,
					}, {
						y: 0,
						duration: 1,
						ease: "power2.out",
					}, 'anim')
					.fromTo($sectionImageWrapIn, {
						scale: 1.2,
					}, {
						scale: 1,
						transformOrigin: '50% 100%',
						duration: 1,
						ease: "expoScale(1.2, 1)",
					}, 'anim')

				if ($sectionTitle) {
					tlProjectImage
						.from(['.project_title_w_in', $sectionDescr], {
							yPercent: 100,
							duration: 1.2,
							stagger: .1,
							ease: Power3.easeOut
						}, 'anim+=.2')
				}

			});

			document.querySelectorAll('.projectParallaxBlock').forEach(($section) => {
				let yOffset = -200;
				let startAnim = "center center";

				if (window.innerWidth <= TABLET_MEDIA_POINT) {
					yOffset = -100;
					startAnim = "-=" + preparedWindowHeight / 1.5;
				}

				gsap.to($section, {
					y: yOffset,
					duration: .4,
					scrollTrigger: {
						scroller: '.section_wrap',
						trigger: $section,
						start: startAnim,
						end: "+=" + preparedWindowHeight,
						scrub: 1
					}
				});
			});

			ScrollTrigger.refresh();

	}, []);

	useEffect(() => {
			if (!isProjectPageAnimDisabled) {
				let tl = gsap.timeline();

				if (logoEl.current && prevLocation !== '/design' && prevLocation !== '/contact' && prevLocation !== '/') {
					gsap
						.from(logoEl.current.children, {
							yPercent: 150,
							duration: .8,
							stagger: .06,
							ease: Power3.easeOut
						})
				}

				tl
					.addLabel('anim')
					.fromTo(projectMainImageEl.current, {
						y: 150,
						opacity: 0,
					}, {
						y: 0,
						opacity: 1,
						duration: 1,
						ease: "power2.out",
					}, 'anim')
					.fromTo(projectMainImageInEl.current, {
						scale: 1.2,
					}, {
						scale: 1,
						transformOrigin: '50% 100%',
						duration: 1,
						ease: "expoScale(1.2, 1)",
					}, 'anim')
			}

	}, []);

	const onScroll = () => {
		scrollTop = sectionWrap.current.scrollTop;
	};

	const tickerAnimation = () => {

		if (pastScrollTop !== scrollTop) {
			// TRANSITION TO HOME PAGE
			// if ((scrollTop + windowHeight) >= projectPreviewElSize.bottomPos) {
			// 	if (windowWidth >= TABLET_MEDIA_POINT) {
			// 		isTransitionToPage = true;
			// 	} else {
			// 		setTimeout(() => {
			// 			isTransitionToPage = true;
			// 		}, 1000);
			// 	}
			// } else {
			// 	isTransitionToPage = false;
			// }

			// SKEW ANIMATION
			if (scrollTop < pastScrollTop) {
				// UP
				isNoScrolling = false;

				if (!isDirectionUp) {
					gsap.to(projectDescr.current, {
						skewY: -skewDirection,
						duration: 1,
						ease: SKEW_ANIM_EASING
					})

				}

				isDirectionUp = true;
				isDirectionDown = false;

			} else if (scrollTop > pastScrollTop) {
				// DOWN
				isNoScrolling = false;

				if (!isDirectionDown) {
					gsap.to(projectDescr.current, {
						skewY: skewDirection,
						transformOrigin: "0% 0%",
						duration: 1,
						ease: SKEW_ANIM_EASING
					})

					isDirectionUp = false;
					isDirectionDown = true;
				}

			}

			pastScrollTop = scrollTop;
		} else {
			if (!isNoScrolling && (isDirectionUp || isDirectionDown)) {

				gsap.to(projectDescr.current, {
					skewY: 0,
					duration: 2,
					ease: SKEW_ANIM_EASING
				})
				isNoScrolling = true;
				isDirectionUp = false;
				isDirectionDown = false;
			}
		}

	};

	const btnBackHandleOnClick = () => {
		history.push('/');
	};

	// const handleMouseWheel = (e) => {
	// 	let upDirection = e.wheelDelta / 120 > 0;
	// 	if (!upDirection && isTransitionToPage) {
	// 		history.push('/');
	// 	}
	// };

	// const handleTouchMove = (e) => {
	// 	let currentTouchY = e.touches[0].clientY;

	// 	if (isTouch && startTouchY > currentTouchY && isTransitionToPage) {
	// 		history.push('/');
	// 	}

	// };

	// const handleTouchStart = (e) => {
	// 	isTouch = true;
	// 	startTouchY = e.touches[0].clientY;
	// };

	// const handleTouchEnd = () => {
	// 	isTouch = false;
	// 	startTouchY = 0;
	// };

	useEffect(() => {
		setTimeout(() => {
			setProjectPreviewElSize({
				bottomPos: sectionWrap.current.offsetTop + sectionWrap.current.scrollHeight,
			});
		}, 0);
	}, [windowWidth]);

	useEffect(() => {
		let $sectionWrapEl = sectionWrap.current;

		if (projectPreviewElSize) {
			$sectionWrapEl.addEventListener('scroll', onScroll);
			gsap.ticker.add(tickerAnimation);
			// $sectionWrapEl.addEventListener('wheel', handleMouseWheel);
			// window.addEventListener("touchstart", handleTouchStart, false);
			// window.addEventListener("touchmove", handleTouchMove, false);
			// window.addEventListener("touchend", handleTouchEnd, false);
		}

		return () => {
			$sectionWrapEl.removeEventListener('scroll', onScroll);
			// $sectionWrapEl.removeEventListener('wheel', handleMouseWheel);
			gsap.ticker.remove(tickerAnimation);
			// window.removeEventListener("touchstart", handleTouchStart, false);
			// window.removeEventListener("touchmove", handleTouchMove, false);
			// window.removeEventListener("touchend", handleTouchEnd, false);
		};

	}, [projectPreviewElSize]);

	return (
		<div className="section_wrap" ref={sectionWrap}>
			<div className="section_wrap_in">
				{windowWidth && windowWidth <= MOBILE_MEDIA_POINT ? (
					<section className="section project_hero_mod inner_mod">
						<Logo v1Mod ref={logoEl} />
					</section>
				) : null}
				<section className="section no_offset_mod inner_mod">
					<div className="project_main_w_image" ref={projectMainImageEl}>
						<div className="project_main_w_image_in" ref={projectMainImageInEl}>
							<img className="project_img" src={mainProjectImage.src} alt={mainProjectImage.alt} />
						</div>
					</div>
				</section>
				<section className="section inner_mod content_anim_mod" ref={sectionContentEl}>
					<div className="project_w_block">
						<div className="section_in v2_mod">
							<div className="project_block">
								<div className="project_col descr_mod">
									<div className="project_descr">
										<div className="project_w_title" ref={projectDescr}>
											{projectName.map((item, index) => {
												return (
													<div className="project_title" key={index}>
														<div className="project_title_w_in">{item}</div>
													</div>
												)
											})}
										</div>
										<div className="project_w_text">
											<div className="project_text">{projectDescription}</div>
										</div>
									</div>
								</div>
								<div className="project_col">
									<div className="project_w_block_in projectParallaxBlock">
										<div className="project_block_img_wrap v1_mod" ref={projectPreview}>
											<div className="project_block_img_wrap_in">
												<img className="project_img" src={firstProjectImage.src} alt={firstProjectImage.alt} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{projectImages.map(({
						src,
						alt,
						alignClass,
						columns,
						isSvg
					}, index) => {
						let isColumns = columns && columns.length;
						let blockClass = isColumns ? 'column_mod' : 'projectParallaxBlock';

						return (
							<div className={`project_w_block ${blockClass}`} key={index}>
								{isColumns ? (
									<div className="project_block_row">
										{columns.map(({
											src: nestedSrc,
											alt: nestedAlt,
										}, nestedIndex) => {
											return (
												<div className="project_w_block_col projectParallaxBlock" key={nestedIndex}>
													<ProjectImage
														src={nestedSrc}
														alt={nestedAlt}
														blockMod={`${alignClass}`}
													/>
												</div>
											)
										})}
									</div>
								) : (
										<ProjectImage
											src={src}
											alt={alt}
											blockMod={alignClass}
											isSvg={isSvg}
										/>
									)}
							</div>
						)
					})}
					<BtnBack
						handleOnClick={btnBackHandleOnClick}
					/>
				</section>
			</div>
		</div>
	);
};

export default ProjectsPage;