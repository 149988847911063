import React from 'react';
import { SvgInline } from 'components/SvgInline';

const ProjectImage = ({
	src,
	alt,
	blockMod,
	isSvg
}) => {
	let blockClass = blockMod || '';

	return (
		<div className={`project_block_img_wrap ${blockClass}`}>
			<div className='project_block_img_wrap_in'>
				{isSvg ? (
					<SvgInline url={src} delay={600} />
				) : (
					<img className="project_block_img" src={src} alt={alt} />
				)}
			</div>
		</div>
	);
};

export default ProjectImage;