export const THEMES = [
	{
		'--body-color': '#fff',
		'--decor-color': '#ff0000',
		'--accent-color': '#000',
		'--accent-color-opacity': '#c1c1c1'
	},
	{
		'--body-color': '#ff0000',
		'--decor-color': '#FFF500',
		'--accent-color': '#000',
		'--accent-color-opacity': 'rgba(0, 0, 0, .4)'
	},
	{
		'--body-color': '#FFF500',
		'--decor-color': '#1900FF',
		'--accent-color': '#000',
		'--accent-color-opacity': 'rgba(0, 0, 0, .3)',
	},
	{
		'--body-color': '#1900FF',
		'--decor-color': '#FF00B8',
		'--accent-color': '#fff',
		'--accent-color-opacity': 'rgba(255, 255, 255, .6)'
	},
	{
		'--body-color': '#FF00B8',
		'--decor-color': '#fff',
		'--accent-color': '#000',
		'--accent-color-opacity': 'rgba(0, 0, 0, .5)'
	}
];

// 1. white
// 2. red
// 3. yellow
// 4. blue
// 5. magenta