import React, { useContext, useRef, useEffect } from 'react';
import { gsap, Power3 } from "gsap";

import { MOBILE_MEDIA_POINT } from 'utils/constant';
import { Logo } from 'components/Logo';
import { FooterAdress } from 'components/Footer';
import { AnimationContext } from 'context/animation';
import { JsonContext } from 'context/json';

const ContactPage = () => {
	const { windowWidth, prevLocation } = useContext(AnimationContext);
	const { jsonData } = useContext(JsonContext);
	const CONTACT_LINKS = jsonData.contactPage.contactLinks;
	const logoEl = useRef(null);

	useEffect(() => {
		if (logoEl.current && prevLocation !== '/design' && prevLocation !== '/') {
			gsap
				.from(logoEl.current.children, {
					yPercent: 150,
					duration: .8,
					stagger: .06,
					ease: Power3.easeOut
				})
		}

		gsap.from('.contact_link', {
			yPercent: 100,
			duration: 1.2,
			stagger: .1,
			delay: .4,
			ease: Power3.easeOut
		})

	}, []);

	return (
		<section className="section hero_mod">
			<div className="section_in hero_mod">
				{windowWidth <= MOBILE_MEDIA_POINT && <Logo ref={logoEl} />}
				<div className="contact_list">
					{CONTACT_LINKS.map(({ text, href }) => {
						return (
							<div className="contact_w_link">
								<a className="contact_link" href={href}>{text}</a>
							</div>
						)
					})}
				</div>
				{windowWidth <= MOBILE_MEDIA_POINT && <FooterAdress />}
			</div>
		</section>
	);
};

export default ContactPage;