import React from 'react';
import Menu from './Menu';

const Header = React.forwardRef((props, ref) => {
	return (
		<header className="header" ref={ref}>
			<div className="section_in header_mod">
				<Menu />
			</div>
		</header>
	);
});

export default Header