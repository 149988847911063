import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main_global.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AnimationState } from 'context/animation';
import { JsonState } from 'context/json';
import { BrowserRouter as Router } from 'react-router-dom';

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<JsonState>
				<AnimationState>
					<App />
				</AnimationState>
			</JsonState>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
