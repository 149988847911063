import React from 'react';

import { ReactComponent as CursorImage } from 'i/сursor.svg';

const Cursor = React.forwardRef(({}, ref) => {
  return (
    <div className="cursor" ref={ref}>
      <CursorImage className="cursor_img" />
    </div>
  );
});

export default Cursor;