import React from 'react';

const ProjectPreviewImg = React.forwardRef(({
	projectImg,
	projectTitle
}, ref) => {
	return (
		<div className="project_preview" ref={ref}>
			<img className="project_preview_img" src={projectImg} alt={projectTitle} />
		</div>
	);
});

export default ProjectPreviewImg;