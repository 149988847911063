import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import classNames from 'classnames';

import { AnimationContext } from 'context/animation';

import { ReactComponent as LogoImage } from 'i/logo.svg';

const Logo = React.forwardRef(({ isHomePageLogo, v1Mod }, ref) => {
	const {
		changeAppTheme,
		setCurrentProjectCategory,
		isMainMenuOpen,
		setMainMenuOpen
	} = useContext(AnimationContext);

	let history = useHistory();

	const logoClasses = classNames('logo', {
		v1_mod: v1Mod,
	});

	const handleOnClick = () => {
		changeAppTheme(0);
		setCurrentProjectCategory(null);

		if (!isHomePageLogo) {
			if (isMainMenuOpen) {
				setMainMenuOpen(false);
			}
			history.push('/');
		}

	};

	return (
		<div className={logoClasses}
			onClick={() => handleOnClick()}
		>
			<LogoImage className="logo_img" ref={ref} />
		</div>
	);
});

export default Logo;