import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import AnimationContext from './animationContext';
import { gsap, Power3 } from "gsap";
import { MOBILE_MEDIA_POINT } from 'utils/constant';
import { THEMES } from 'utils/theme';
import { JsonContext } from 'context/json';

// ANIMATION STATE ДЛЯ ТОГО ЧТОБЫ ХРАНИТЬ КАКИЕ-ТО ГЛОБАЛЬНЫЕ ВЕЩИ ПОД АНИМАЦИЮ, ТАКИЕ КАК ID ПРЕВЬЮ И Т.Д
const AnimationState = ({ children }) => {
	const [isPageLoad, setPageLoad] = useState(false);
	const [headerEl, setHeaderEl] = useState(null);
	const { jsonData } = useContext(JsonContext);
	const [footerEl, setFooterEl] = useState(null);
	const [currentProjectPreviewId, setCurrentProjectPreviewId] = useState(null);
	const [currentProjectCategory, setCurrentProjectCategory] = useState(null);
	const [windowHeight, setWindowHeight] = useState(null);
	const [windowWidth, setWindowWidth] = useState(null);
	const [rem, setRem] = useState(null);
	const [currentThemeIndex, setCurrentThemeIndex] = useState(0);
	const [isGlobalElVisible, setGlobalElVisible] = useState(false);
	const [isMainMenuOpen, setMainMenuOpen] = useState(false);
	const [isMainMenuAnimDone, setMainMenuAnimDone] = useState(false);
	const [prevLocation, setPrevLocation] = useState(null);
	const [isProjectPageAnimDisabled, setProjectPageAnimDisabled] = useState(false);
	const [isPagesAnimDisabled, setPagesAnimDisabled] = useState(false);
	const [isDropDownMenuOpen, setDropDownMenuOpen] = useState(false);
	const [globalElTimeline] = useState(gsap.timeline({
		paused: true,
		onComplete: () => setGlobalElVisible(true)
	}));
	let location = useLocation();

	const onResize = () => {
		let innerHeight = window.innerHeight;
		let calcRemValue = window.outerWidth * .5625 > innerHeight ? innerHeight / 1080 * 10 : window.outerWidth / 1920 * 10;

		setWindowHeight(innerHeight);
		setWindowWidth(window.innerWidth);
		setRem(calcRemValue);

		document.documentElement.style.setProperty('--rem', calcRemValue + 'px');
	};

	useEffect(() => {
		if (isPageLoad && jsonData) {
			globalElTimeline
				.addLabel('start')
				.from(['.footer_decor'], {
					scale: 0,
					duration: 1.2,
					ease: Power3.easeOut,
				}, 'start+=.6')
				.addLabel('finish')

			if (window.innerWidth > MOBILE_MEDIA_POINT) {
				globalElTimeline
					.from(['.header', '.footer'], {
						yPercent: gsap.utils.wrap([-100, 100]),
						duration: .9,
						ease: Power3.easeOut,
					}, 'start')
			} else {
				console.log('mobile');
				globalElTimeline
					.from('.footer', {
						yPercent: 100,
						duration: .9,
						ease: Power3.easeOut,
					}, 'start')
			}

			if (location.pathname !== '/' && !isGlobalElVisible) {
				globalElTimeline.play();
			}

		}


	}, [isPageLoad, jsonData]);

	const changeAppTheme = themeIndex => {
		let updatedCurrentThemeIndex = themeIndex !== undefined ? themeIndex : currentThemeIndex + 1;
		let root = document.documentElement;

		if (updatedCurrentThemeIndex > THEMES.length - 1) updatedCurrentThemeIndex = 0;

		let updatedCurrentTheme = THEMES[updatedCurrentThemeIndex];

		Object.keys(updatedCurrentTheme).map(key => root.style.setProperty(key, updatedCurrentTheme[key]));

		setCurrentThemeIndex(updatedCurrentThemeIndex);
	};

	useEffect(() => {
		onResize();
		setPageLoad(true);
		setPrevLocation(location.pathname);
		window.addEventListener('resize', onResize);
	}, []);

	return (
		<AnimationContext.Provider
			value={{
				setHeaderEl,
				setFooterEl,
				headerEl,
				footerEl,
				currentProjectPreviewId,
				setCurrentProjectPreviewId,
				isGlobalElVisible,
				setGlobalElVisible,
				windowHeight,
				windowWidth,
				currentProjectCategory,
				setCurrentProjectCategory,
				globalElTimeline,
				isMainMenuOpen,
				setMainMenuOpen,
				isPageLoad,
				prevLocation,
				changeAppTheme,
				setPrevLocation,
				isProjectPageAnimDisabled,
				setProjectPageAnimDisabled,
				isDropDownMenuOpen,
				isMainMenuAnimDone,
				setMainMenuAnimDone,
				setDropDownMenuOpen,
				isPagesAnimDisabled,
				setPagesAnimDisabled
			}}
		>
			{children}
		</AnimationContext.Provider>
	);

};

export default AnimationState;