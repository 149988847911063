import React, { useContext, useEffect, useState, useRef } from 'react';
import { gsap, Power3 } from "gsap";
import { useHistory } from "react-router-dom";

import { HEADER_NAV } from './constant';
import { MOBILE_MEDIA_POINT } from 'utils/constant';
// import { ReactComponent as LogoImage } from 'i/logo.svg';
import { AnimationContext } from 'context/animation';
import { ProjectMenu } from 'components/Project';
import { Logo } from 'components/Logo';
import { JsonContext } from 'context/json';

const Menu = () => {
	// COMMENT: чтобы открыть вложенное мобильное меню надо поставить isDropDownMenuOpen = true 
	const {
		windowWidth,
		isMainMenuOpen,
		isPageLoad,
		setMainMenuOpen,
		footerEl,
		isDropDownMenuOpen,
		isGlobalElVisible,
		isMainMenuAnimDone,
		setMainMenuAnimDone,
		setPagesAnimDisabled,
		setDropDownMenuOpen
	} = useContext(AnimationContext);
	const { jsonData } = useContext(JsonContext);
	const footerCopyrightEl = useRef(null);
	const menuWrapEl = useRef(null);
	const menuListEl = useRef(null);
	const footerCopyrightTitleEl = useRef(null);
	const [tlDropDownMenu] = useState(gsap.timeline({
		paused: true
	}));
	const { copyright } = jsonData;
	let history = useHistory();

	const handleDropDownMenu = () => {
		setDropDownMenuOpen(prevState => !prevState);
	};

	const handleOnClick = url => {
		if (isMainMenuOpen) {
			setMainMenuOpen(false)
			setPagesAnimDisabled(true);
		}
		history.push(url);
	};

	const checkAnimDone = () => {
		setMainMenuAnimDone(prevState => !prevState);
	};

	const [tlMenu] = useState(gsap.timeline({
		paused: true,
		onComplete: checkAnimDone
	}));

	useEffect(() => {
		if (isPageLoad) {
			if (windowWidth <= MOBILE_MEDIA_POINT) {
				let $projectMenu = document.querySelector('.project_menu');

				const removeMenuElClasses = () => {
					if (menuListEl.current.classList.contains('disabled_mod')) {
						$projectMenu.classList.remove('active_mod');
						menuListEl.current.classList.remove('disabled_mod');
						footerCopyrightEl.current.classList.remove('disabled_mod');
					}
				};

				tlMenu
					.addLabel('forward_start')
					.to([menuWrapEl.current, '.footer_overlay'], {
						opacity: 1,
						pointerEvents: "auto",
						duration: .4,
						onStart: checkAnimDone
					})
					.from(['.main_menu_link', footerCopyrightTitleEl.current], {
						yPercent: 100,
						duration: .6,
						stagger: .06,
						ease: Power3.easeOut,
						onComplete: checkAnimDone
					}, 'forward+=.2')
					.addLabel('forward_finish')
					.addLabel('backward_start')
					.to([menuWrapEl.current, '.footer_overlay'], {
						onStart: checkAnimDone,
						opacity: 0,
						pointerEvents: "none",
						duration: .6,
						onComplete: removeMenuElClasses
					})
					.to(footerEl.current,
						{
							duration: .1,
							className: '-=footer',
							immediateRender: false
						}, 'backward_start')
					.addLabel('backward_finish')

				tlDropDownMenu
					.addLabel('anim')
					.to([menuListEl.current, footerCopyrightEl.current], {
						x: -100,
						opacity: 0,
						ease: Power3.easeOut,
						duration: .4
					})
					.fromTo($projectMenu, {
						x: 100,
						opacity: 0,
					}, {
						x: 0,
						opacity: 1,
						duration: .6,
						ease: Power3.easeOut,
						onComplete: () => {
							$projectMenu.classList.add('active_mod');
							menuListEl.current.classList.add('disabled_mod');
							footerCopyrightEl.current.classList.add('disabled_mod');
						},
						onReverseComplete: removeMenuElClasses
					}, 'anim')
			}
		}

	}, [isPageLoad])

	// Переделать
	useEffect(() => {
		if (isMainMenuOpen) {
			tlMenu.tweenFromTo('forward_start', 'forward_finish');
		} else if (!isMainMenuOpen && tlMenu.progress()) {
			tlMenu.tweenFromTo('backward_start', 'backward_finish');
			if (isDropDownMenuOpen) setDropDownMenuOpen(false);
		}
	}, [isMainMenuOpen]);

	// Переделать
	useEffect(() => {
		if (windowWidth <= MOBILE_MEDIA_POINT) {
			if (isDropDownMenuOpen) {
				tlDropDownMenu.play();
			} else if (!isDropDownMenuOpen && tlDropDownMenu.progress()) {
				tlDropDownMenu.reverse();
			}
		}
	}, [isDropDownMenuOpen]);

	return (
		<div className="menu_wrap" ref={menuWrapEl}>
			{windowWidth && windowWidth <= MOBILE_MEDIA_POINT && (
				<Logo />
				// <div className="logo">
				// 	<Logo className="logo_img" />
				// </div>
			)}
			<ul className="main_menu_list" ref={menuListEl}>
				{HEADER_NAV.map(({ title, url, dropdownMenu }) => {
					return (
						<li className="main_menu_item" key={title + url}>
							{windowWidth && windowWidth <= MOBILE_MEDIA_POINT && dropdownMenu ? (
								<button
									className="main_menu_link"
									onClick={handleDropDownMenu}
								>{title}
								</button>
							) : (
									<button
										className="main_menu_link"
										disabled={(!isGlobalElVisible) || (isMainMenuAnimDone)}
										onClick={() => handleOnClick(url)}
									>{title}
									</button>
								)}
						</li>
					)
				})}
			</ul>
			{windowWidth && windowWidth <= MOBILE_MEDIA_POINT && (
				<div className="footer_copyright menu_mod" ref={footerCopyrightEl}>
					<div className="footer_copyright_in">
						<div className="footer_copyright_in_title" ref={footerCopyrightTitleEl}>{copyright}</div>
					</div>
				</div>
			)}
			{windowWidth && windowWidth <= MOBILE_MEDIA_POINT ? (
				<ProjectMenu />
			) : null}
		</div>
	);
};

export default Menu;