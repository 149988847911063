import React, { useState, useEffect, useRef, useContext } from 'react';
import { gsap, Power3 } from "gsap";

// Components
import { CategoryList } from 'components/CategoryList';
import { ProjectLinks } from 'components/Project';
import { Logo } from 'components/Logo';
import { AnimationContext } from 'context/animation';

// Assets

const HomePage = () => {
	const { headerEl, footerEl, isGlobalElVisible, setGlobalElVisible, globalElTimeline, currentProjectCategory, setCurrentProjectCategory } = useContext(AnimationContext);
	const [tl] = useState(gsap.timeline({
		paused: false,
		delay: .3,
		onComplete: () => setGlobalElVisible(true)
	}));
	const sectionEl = useRef(null);
	const logoEl = useRef(null);

	// Filtration by category
	const selectProjectCategory = (e) => {
		let projectCategory = e.target.dataset.category.toLowerCase();

		setCurrentProjectCategory(projectCategory);
	};

	// АНИМАЦИЯ ГЛАВНОЙ СТРАНИЦЫ
	useEffect(() => {
		if (headerEl && footerEl) {
			tl
				.addLabel('anim')
				.from(sectionEl.current, {
					opacity: 0,
					duration: .33,
					ease: Power3.easeOut
				})
				.from(logoEl.current.children, {
					yPercent: 150,
					duration: .8,
					stagger: .06,
					ease: Power3.easeOut
				})
				.from(['.category_link', '.project_link_title'], {
					yPercent: 100,
					duration: 1.2,
					ease: Power3.easeOut
				}, 'anim+=.8')

			if (!isGlobalElVisible) {
				tl.add(globalElTimeline.play(), 'anim+=.4')
			}

		}
	}, [headerEl, footerEl]);

	return (
		<>
			<section className="section hero_mod home_mod" ref={sectionEl}>
				<div className="section_in">
					<Logo ref={logoEl} isHomePageLogo />
					<CategoryList
						selectProjectCategory={selectProjectCategory}
						currentProjectCategory={currentProjectCategory}
					/>
					<ProjectLinks
						currentProjectCategory={currentProjectCategory}
					/>
				</div>
			</section>

		</>
	);

};

export default HomePage;