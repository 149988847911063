import React from 'react';
import { ReactComponent as ArrowDown } from 'i/arrow_down.svg';

const BtnBack = ({
	handleOnClick
}) => {
	return (
		<div className="btn_back" onClick={handleOnClick}>
			<div className="btn_back_title">BACK TO MENU</div>
			<div className="btn_back_arrow">
				<ArrowDown className="btn_back_arrow_in" />
			</div>
		</div>
	);
};

export default BtnBack;