import React, { useState } from 'react';

import JsonContext from './jsonContext';

const JsonState = ({ children }) => {
	const [jsonData, setJsonData] = useState(null);

	return (
		<JsonContext.Provider value={{
			jsonData,
			setJsonData
		}}>
			{children}
		</JsonContext.Provider>
	);

};

export default JsonState;