import React, { useEffect, useState } from 'react';

const SvgInline = ({
	url,
	delay,
}) => {
	const [svg, setSvg] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isErrored, setIsErrored] = useState(false);
	const svgDelay = delay || 0;

	useEffect(() => {
		fetch(url)
			.then(res => res.text())
			.then(res => {
				setTimeout(() => {
					setSvg(res);
				}, svgDelay);
			})
			.catch(setIsErrored)
			.then(() => setIsLoaded(true))
	}, []);

	return (
		<div
			className={`svgInline svgInline--${isLoaded ? 'loaded' : 'loading'} ${isErrored ? 'svgInline--errored' : ''}`}
			dangerouslySetInnerHTML={{ __html: svg }}
		/>
	);
};

export default SvgInline;