import React, { useContext, useEffect, useRef, useState } from 'react';
import { AnimationContext } from 'context/animation';
import { useHistory } from "react-router-dom";

import { ProjectPreview } from "./index";
import { gsap } from "gsap";
import { TABLET_MEDIA_POINT } from 'utils/constant';
import { JsonContext } from 'context/json';

const ProjectLinks = ({ currentProjectCategory }) => {
	const { jsonData } = useContext(JsonContext);
	const { currentProjectPreviewId, setCurrentProjectPreviewId, setProjectPageAnimDisabled, windowWidth, windowHeight } = useContext(AnimationContext);
	// const onMouseLeave = () => ;
	const projectPreviewEl = useRef(null);
	const projectPreviewElIn = useRef(null);
	const [projectPreviewElSize, setProjectPreviewElSize] = useState(null);
	const [projectPreviewElInSize, setProjectPreviewElInSize] = useState(null);
	const [isPageTransitionActive, setPageTransitionActive] = useState(false);
	let history = useHistory();
	const PROJECTS_LINKS = jsonData.homePage.projectsLinks;

	const project_preview_ref = React.createRef();
	const offsetScreenBoundaries = 20;

	const onMouseEnter = id => {
		if (!isPageTransitionActive && windowWidth > TABLET_MEDIA_POINT) {
			setCurrentProjectPreviewId(id)
		}
	};

	const onMouseLeave = () => {
		if (!isPageTransitionActive) {
			setCurrentProjectPreviewId(null);
		}
	};

	const onMouseMove = e => {
		if (!isPageTransitionActive && windowWidth > TABLET_MEDIA_POINT) {
			let projectPreviewHalfWidthSize = projectPreviewElSize.width / 2;
			let projectPreviewHalfHeightSize = projectPreviewElSize.height / 2;

			let xCoordinate = e.pageX - projectPreviewHalfWidthSize;
			let yCoordinate = e.pageY - projectPreviewHalfHeightSize;

			let preparedXCoordinate = (e.pageX + projectPreviewElInSize.width / 2) - windowWidth;
			let preparedYCoordinate = (e.pageY + projectPreviewElInSize.height / 2) - windowHeight;


			if (e.pageX < projectPreviewElInSize.width / 2) {
				xCoordinate = ((projectPreviewElSize.width - projectPreviewElInSize.width) / 2 - offsetScreenBoundaries) * -1;
			}

			if (e.pageX + projectPreviewElInSize.width / 2 > windowWidth) {
				xCoordinate = xCoordinate - preparedXCoordinate - offsetScreenBoundaries;
			}

			if (e.pageY + projectPreviewElInSize.height / 2 > windowHeight) {
				yCoordinate = yCoordinate - preparedYCoordinate - offsetScreenBoundaries;
			}

			gsap.to(projectPreviewEl.current, {
				y: yCoordinate,
				x: xCoordinate
			});

		}

	};

	const handleOnClick = projectSlug => {
		if (windowWidth >= TABLET_MEDIA_POINT) {
			setPageTransitionActive(true);

			let tl = gsap.timeline({
				paused: false,
				onComplete: () => {
					setCurrentProjectPreviewId(null);
					setProjectPageAnimDisabled(true);

					history.push(`/projects/${projectSlug}`);
				}
			});

			tl
				.addLabel('anim')
				.set(projectPreviewEl.current, { zIndex: 100 })
				.to(projectPreviewEl.current, {
					x: 0,
					y: 0,
					duration: .8,
					// ease: "expoScale(.5, 1)",
				}, 'anim')
				.to('.project_preview_w_in', {
					scale: 1,
					duration: .8,
					ease: "expoScale(.26, 1)",
				}, 'anim')
				.to(project_preview_ref.current, {
					scale: 1,
					duration: .8,
					ease: "expoScale(2.46, 1)",
				}, 'anim')
		} else {
			history.push(`/projects/${projectSlug}`);
		}
	};

	useEffect(() => {
		if (windowWidth >= TABLET_MEDIA_POINT) {
			setProjectPreviewElSize({
				width: projectPreviewEl.current.offsetWidth,
				height: projectPreviewEl.current.offsetHeight
			});
			setProjectPreviewElInSize(projectPreviewElIn.current.getBoundingClientRect());
		}
	}, [windowWidth]);

	useEffect(() => {
		if (isPageTransitionActive) onMouseLeave();

		if (projectPreviewElSize) document.addEventListener('mousemove', onMouseMove);

		return () => document.removeEventListener('mousemove', onMouseMove);

	}, [projectPreviewElSize, isPageTransitionActive])

	return (
		<div className="project_links">

			{PROJECTS_LINKS.map(({ title, category, slug }, index) => {
				return (
					<div className="project_link_item" key={title} onClick={() => handleOnClick(slug)}>
						<div
							className={`project_link ${currentProjectCategory && currentProjectCategory !== category.toLowerCase() ? 'disabled_mod' : ''}`}
							onMouseOver={() => onMouseEnter(index)}
							onMouseLeave={onMouseLeave}
						>
							<div className="project_link_w_title">
								<div className="project_link_title">{title}</div>
							</div>
						</div>
					</div>
				)
			})}
			<div className="project_preview_w" ref={projectPreviewEl}>
				<div className="project_preview_w_in" ref={projectPreviewElIn}>
					{(currentProjectPreviewId !== null ?
						<ProjectPreview
							ref={project_preview_ref}
							projectImg={PROJECTS_LINKS[currentProjectPreviewId].img}
							projectTitle={PROJECTS_LINKS[currentProjectPreviewId].title}
						/>
						: null)}
				</div>
			</div>
		</div>
	);
};

export default ProjectLinks;