import React, { useEffect, useContext, useRef } from 'react';
import { gsap, Power3 } from "gsap";

import { Logo } from 'components/Logo';
import { MOBILE_MEDIA_POINT } from 'utils/constant';
import { AnimationContext } from 'context/animation';
import { JsonContext } from 'context/json';

const DesignPage = () => {
	const { windowWidth, prevLocation } = useContext(AnimationContext);
	const { jsonData } = useContext(JsonContext);
	const logoEl = useRef(null);
	const { title, descrOne, descrSecond } = jsonData.designPage;

	useEffect(() => {
		gsap.from(['.design_title', '.design_text_wrap_in'], {
			yPercent: 100,
			duration: 1.2,
			stagger: .1,
			delay: .4,
			ease: Power3.easeOut
		})
	}, []);

	useEffect(() => {
		if (logoEl.current && prevLocation !== '/contact' && prevLocation !== '/') {
			gsap.from(logoEl.current.children, {
				yPercent: 150,
				duration: .8,
				stagger: .06,
				ease: Power3.easeOut
			})
		}
	}, [])

	return (
		<section className="section hero_mod">
			<div className="section_in hero_mod">
				{windowWidth <= MOBILE_MEDIA_POINT && <Logo ref={logoEl} />}
				<div className="design_block">
					<div className="design_block_col">
						<div className="design_block_w_title">
							{title.map(text => {
								return (
									<div className="design_w_title">
										<div className="design_title">{text}</div>
									</div>
								)
							})}
						</div>
					</div>
					<div className="design_block_col">
						<div className="design_text">
							<div className="design_text_wrap">
								<div className="design_text_wrap_in">
									<p>{descrOne}</p>
								</div>
							</div>
							<div className="design_text_wrap">
								<div className="design_text_wrap_in">
									<p>{descrSecond}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default DesignPage;