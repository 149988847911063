import React, { useState, useEffect, useRef, useContext } from 'react';
import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import ProjectMenuBlock from './ProjectMenuBlock';

import { AnimationContext } from 'context/animation';

gsap.registerPlugin(ScrollTrigger);

const ProjectMenu = () => {
	const projectMenuEl = useRef(null);
	const projectMenuElIn = useRef(null);
	const projectMenuBlockEl = useRef(null);
	const projectMenuBlockSecondEl = useRef(null);
	const [tlMenu] = useState(gsap.timeline({
		// repeat: -1,
		paused: true
	}));
	const {
		isDropDownMenuOpen,
	} = useContext(AnimationContext);

	const [projectMenuPos, setProjectMenuPos] = useState(null);
	// const scrollBar = {};

	const SKEW_ANIM_EASING = "power3.out";
	const SKEW_ANIM_DURATION = 1;

	let scrollTop = 0;
	let pastScrollTop = false;
	let skewDirection = 1.5;
	let isDirectionUp = false;
	let isDirectionDown = false;
	let isNoScrolling = true;

	// ,
	// 	scrollTrigger: {
	// 		// scroller: '.wrapper',
	// 		trigger: ".project_menu_container",
	// 		start: "top top",
	// 		scrub: true,
	// 		pin: true,
	// 	}

	// const [tl] = useState(gsap.timeline({
	// }));

	// gsap.timeline({
	// 	ease: "none",
	// 	scrollTrigger: {
	// 		// scroller: '.wrapper',
	// 		trigger: ".vehicleTrigger",
	// 		start: "bottom bottom",
	// 		end: "+=" + windowHeight * 2,
	// 		scrub: true,
	// 		pin: true,
	// 	}
	// })

	// useEffect(() => {
	// 	tlMenu
	// 		.addLabel('start')
	// 		.fromTo(projectMenuBlockEl.current, {
	// 			yPercent: 0,
	// 		}, {
	// 			yPercent: -100,
	// 			duration: 4,
	// 			ease: 'none'
	// 		})
	// 		.fromTo(projectMenuBlockSecondEl.current, {
	// 			yPercent: 0,
	// 		}, {
	// 			yPercent: -100,
	// 			duration: 4,
	// 			ease: 'none'
	// 		}, 'start')
	// }, []);

	// useEffect(() => {
	// 	// scrollBar.addListener = ({offset}) => {
	// 	// 	console.log(offset.x);
	// 	// };

	// 	ScrollTrigger.scrollerProxy(projectMenuContainer.current, {
	// 		scrollTop(value) {
	// 			if (arguments.length) {
	// 				scrollBar.scrollTop = value;
	// 			}
	// 			return scrollBar.scrollTop;
	// 		}
	// 	});

	// 	ScrollTrigger.create({
	// 		trigger: projectMenuContainerIn.current,
	// 		start: "top top",
	// 		animation: tlMenu,
	// 		pin: false,
	// 		anticipatePin: 1,
	// 		scrub: true
	// 	});

	// 	// scrollBar.addListener(ScrollTrigger.update);
	// }, []);

	// useEffect(() => {
	// 	tlMenu
	// 		.addLabel('start')
	// 		.to(projectMenuBlockEl.current, {
	// 			yPercent: -100,
	// 			duration: 4,
	// 			ease: 'none'
	// 		})
	// 		.to(projectMenuBlockSecondEl.current, {
	// 			yPercent: -100,
	// 			duration: 4,
	// 			ease: 'none'
	// 		}, 'start')
	// }, []);

	// SMELL CODE
	// const onScroll = (e) => {
	// 	let scrollTop = projectMenuEl.current.scrollTop;
	// 	let preparedHeight = projectMenuEl.current.scrollHeight - projectMenuEl.current.clientHeight;
	// 	let progress = scrollTop / preparedHeight;


	// 	const easeFunc = gsap.parseEase("power2.out");
	// 	const tween = gsap.to({foo: 0}, {foo: 10, duration: 1, ease: "power2.out"});
	// 	tween.pause(0.5); // pause at 0.5 seconds which is halfway in this 1-second tween

	// 	console.log(tween.progress());           // 0.5
	// 	console.log(tween.ratio);                // 0.875
	// 	console.log(easeFunc(tween.progress())); // 0.875

	// 	// console.log(scrollTop / progress);
	// 	// console.log(progress);
	// 	// tlMenu.progress(progress);
	// 	// tlMenu.tweenFromTo();
	// };

	// useEffect(() => {
	// 	let $projectMenuEl = projectMenuEl.current;

	// 	$projectMenuEl.addEventListener('scroll', onScroll);

	// 	return () => {
	// 		$projectMenuEl.removeEventListener('scroll', onScroll);
	// 	};

	// }, []);

	const onScroll = () => {
		scrollTop = projectMenuEl.current.scrollTop;
	};

	const tickerAnimation = () => {


		if (pastScrollTop !== scrollTop) {
			// console.log(pastScrollTop);

			// SKEW ANIMATION
			if (scrollTop < pastScrollTop) {
				// UP
				isNoScrolling = false;

				if (!isDirectionUp) {
					gsap.to(projectMenuElIn.current, {
						skewY: -skewDirection,
						duration: 1,
						ease: SKEW_ANIM_EASING
					})
				}

				isDirectionUp = true;
				isDirectionDown = false;
				
			} else if (scrollTop > pastScrollTop) {
				// DOWN
				isNoScrolling = false;

				if (!isDirectionDown) {
					gsap.to(projectMenuElIn.current, {
						skewY: skewDirection,
						duration: 1,
						ease: SKEW_ANIM_EASING
					})
					// }
					
					// console.log('down');
					
					isDirectionUp = false;
					isDirectionDown = true;
				}

			}

			pastScrollTop = scrollTop;
		} else {
			if (!isNoScrolling && (isDirectionUp || isDirectionDown)) {
				console.log('whyu');
				gsap.to(projectMenuElIn.current, {
					skewY: 0,
					duration: 2,
					ease: SKEW_ANIM_EASING
				})
				isNoScrolling = true;
				isDirectionUp = false;
				isDirectionDown = false;
			}
		}

	};

	
	useEffect(() => {
		setProjectMenuPos({
			bottomPos: projectMenuEl.current.offsetTop + projectMenuEl.current.scrollHeight,
		});
	}, []);

	useEffect(() => {
		let $projectMenuEl = projectMenuEl.current;

		if (projectMenuPos && isDropDownMenuOpen) {
			gsap.ticker.add(tickerAnimation);
			$projectMenuEl.addEventListener('scroll', onScroll);
		}

		return () => {
			$projectMenuEl.removeEventListener('scroll', onScroll);
			gsap.ticker.remove(tickerAnimation);
		};

	}, [projectMenuPos, isDropDownMenuOpen])

	return (
		<div className="project_menu" ref={projectMenuEl}>
			<div className="project_menu_in" ref={projectMenuElIn}>
				<ProjectMenuBlock />
			</div>
		</div>
	);
};

export default ProjectMenu;