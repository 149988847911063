export const HEADER_NAV = [
  {
    title: 'PROJECTS',
    url: '/',
    dropdownMenu: true
  },
  {
    title: 'CONTACT',
    url: '/contact'
  },
  {
    title: 'DESIGN + BUILD',
    url: '/design'
  }
];