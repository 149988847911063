import React, { useContext } from 'react';

import { AnimationContext } from 'context/animation';

const MenuTrigger = () => {
	const {
		isMainMenuOpen,
		setMainMenuOpen,
		isDropDownMenuOpen,
		setDropDownMenuOpen,
		isMainMenuAnimDone
	} = useContext(AnimationContext);

	const handleOnClick = () => {

		if (isDropDownMenuOpen) {
			setDropDownMenuOpen(false);
		} else {
			setMainMenuOpen(prevState => !prevState);
		}

	};

	return (
		<button
			className={`menu_trigger menuTrigger ${isMainMenuOpen ? 'active_mod' : ''}`}
			disabled={isMainMenuAnimDone}
			onClick={handleOnClick}
		/>
	);
};

export default MenuTrigger;