import React, { useEffect, useRef, useContext } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Transition, TransitionGroup } from 'react-transition-group';
import { gsap } from "gsap";

import { Header, Menu } from 'components/Header';
import { Footer } from 'components/Footer';
import { Cursor } from 'components/Cursor';
import { TABLET_MEDIA_POINT, MOBILE_MEDIA_POINT } from 'utils/constant';
import * as PAGES from './pages';
import { AnimationContext } from 'context/animation';
import { JsonContext } from 'context/json';

function App() {
	const { jsonData, setJsonData } = useContext(JsonContext);

	const headerEl = useRef(null);
	const footerEl = useRef(null);
	const cursorEl = useRef(null);
	const {
		setHeaderEl,
		setFooterEl,
		windowWidth,
		setPrevLocation,
		isProjectPageAnimDisabled,
		setProjectPageAnimDisabled,
		isMainMenuOpen,
		isPagesAnimDisabled,
		setPagesAnimDisabled,
	} = useContext(AnimationContext);
	const location = useLocation();

	useEffect(() => {
		setHeaderEl(headerEl);
		setFooterEl(footerEl);
	}, []);

	const onMouseMove = e => {
		if (cursorEl.current) {
			let xCoordinate = e.pageX;
			let yCoordinate = e.pageY;

			gsap.to(cursorEl.current, {
				y: yCoordinate,
				x: xCoordinate,
				duration: 0.5,
			});
		}

	};

	const onEnter = node => {
		const pageAnim = () => {
			gsap.from(
				node,
				{
					opacity: 0,
					ease: "power3.InOut",
					duration: .6,
					onComplete: () => {
						setPrevLocation(location.pathname);
						setProjectPageAnimDisabled(false);
						setPagesAnimDisabled(false);
					}
				}
			);
		};

		if (windowWidth > MOBILE_MEDIA_POINT && !isProjectPageAnimDisabled) {
			pageAnim();
		}

		if (windowWidth <= MOBILE_MEDIA_POINT) {
			setPrevLocation(location.pathname);
		}

	};

	const onExit = node => {
		let animDuration = .6;

		if (isMainMenuOpen) {
			animDuration = .0001;
		}

		gsap.fromTo(
			node,
			{
				opacity: 1,
			},
			{
				opacity: 0,
				ease: "power3.InOut",
				duration: animDuration,
			}
		);
	};

	useEffect(() => {
		document.addEventListener('mousemove', onMouseMove);

		return () => {
			document.removeEventListener('mousemove', onMouseMove);
		};

	}, [windowWidth]);

	useEffect(() => {
		(async () => {
			try {
				const rawResponse = await fetch('../data/jsonData.json');

				const content = await rawResponse.json();

				setJsonData(content);

			} catch (err) {
				console.log(err);
			}

		})();
	}, []);

	// useEffect(() => {
	// 	setJsonData({
	// 		"copyright": "© 2020",
	// 		"companyLocation": "49 Elizabeth Street, New York, NY 10013",
	// 		"homePage": {
	// 			"categoryNav": [
	// 				{
	// 					"title": "Residential"
	// 				},
	// 				{
	// 					"title": "Commercial"
	// 				},
	// 				{
	// 					"title": "Hospitality"
	// 				},
	// 				{
	// 					"title": "Installation"
	// 				}
	// 			],
	// 			"projectsLinks": [
	// 				{
	// 					"title": "TMRW",
	// 					"slug": "tmrw",
	// 					"category": "Commercial",
	// 					"img": "/i/projects/tmrw/CKMS-Commercial-TMRW-01.jpg"
	// 				},
	// 				{
	// 					"title": "NH Madison",
	// 					"slug": "neue-house-madison",
	// 					"category": "Commercial",
	// 					"img": "/i/projects/nh-madison/CKMS-Commercial-Neue-House-01.jpg"
	// 				},
	// 				{
	// 					"title": "NH Penthouse",
	// 					"slug": "neue-house-penthouse",
	// 					"category": "Commercial",
	// 					"img": "/i/projects/nh-penthouse/CKMS-Commercial-Neue-House-Penthouse-01.jpg"
	// 				},
	// 				{
	// 					"title": "59-17 Bleeker Street",
	// 					"slug": "bleeker-street",
	// 					"category": "Residential",
	// 					"img": "/i/projects/bleecker/CKMS-Residential-59-17-Bleecker-01.jpg"
	// 				},
	// 				{
	// 					"title": "Dstillery",
	// 					"slug": "dstillery",
	// 					"category": "Commercial",
	// 					"img": "/i/projects/dstillery/CKMS-Commercial-Dstillery-01.jpg"
	// 				},
	// 				{
	// 					"title": "Sid Lee",
	// 					"slug": "sid-lee",
	// 					"category": "Commercial",
	// 					"img": "/i/projects/sid-lee/CKMS-Commercial-Sid-Lee-01.jpg"
	// 				},
	// 				{
	// 					"title": "Greca",
	// 					"slug": "greca",
	// 					"category": "Hospitality",
	// 					"img": "/i/projects/greca/CKMS-Hospitality-Greca-01.jpg"
	// 				},
	// 				{
	// 					"title": "Ski Project",
	// 					"slug": "ski-project",
	// 					"category": "Commercial",
	// 					"img": "/i/projects/ski-project/CKMS-Commercial-Ski-Project-01.jpg"
	// 				},
	// 				{
	// 					"title": "Cafe Roma",
	// 					"slug": "cafe-roma",
	// 					"category": "Hospitality",
	// 					"img": "/i/projects/cafe-roma/CKMS-Hospitality-Cafe-Roma-01.jpg"
	// 				},
	// 				{
	// 					"title": "Tabula Rasa",
	// 					"slug": "tabula-rasa",
	// 					"category": "Residential",
	// 					"img": "/i/projects/al-naim/CKMS-Residential-Al-Naim-01.jpg"
	// 				},
	// 				{
	// 					"title": "Folly Function",
	// 					"slug": "folly-function",
	// 					"category": "Installation",
	// 					"img": "/i/projects/folly-function/CKMS-Installation-Folly-Function-01.jpg"
	// 				},
	// 				{
	// 					"title": "Cross Fit",
	// 					"slug": "cross-fit",
	// 					"category": "Commercial",
	// 					"img": "/i/projects/cross-fit/CKMS-Commercial-Cross-Fit-01.jpg"
	// 				}
	// 			]
	// 		},
	// 		"contactPage": {
	// 			"contactLinks": [
	// 				{
	// 					"text": "STUDIO@CKMS.DESIGN",
	// 					"href": "mailto:STUDIO@CKMS.DESIGN"
	// 				},
	// 				{
	// 					"text": "+1 (646) 286 3624",
	// 					"href": "tel:+16462863624"
	// 				}
	// 			]
	// 		},
	// 		"designPage": {
	// 			"title": [
	// 				"Design",
	// 				"Build"
	// 			],
	// 			"descrOne": "CKMS is a design + build company based in New York City. We are managed by a team of architects and designers who believe in offering an integrated service for design + construction. Contrary to industry norms, we believe in the integration of design and construction by a single team, and in contrast to most design firms, our process is as mindful of constructability and budget as it is of the design vision.",
	// 			"descrSecond": "Contact us for more details."
	// 		},
	// 		"projectsPage": {
	// 			"tmrw": {
	// 				"projectName": [
	// 					"2019 /",
	// 					"TMRW Life",
	// 					"Sciences"
	// 				],
	// 				"projectDescription": "We were asked to design+build the corporate headquarters for a biotech startup, our proposal was for a simple and functional intervention with large scale but lightweight workstations designed to be seamless in order to easily allow the workforce to expand and contract. The tables are constructed using materials intended to evoke early science lab aesthetics such as welded tubular steel and linoleum worktops with teak borders.",
	// 				"mainProjectImage": {
	// 					"src": "/i/projects/tmrw/CKMS-Commercial-TMRW-01.jpg",
	// 					"alt": "main project image"
	// 				},
	// 				"firstProjectImage": {
	// 					"src": "/i/projects/tmrw/CKMS-Commercial-TMRW-02.jpg",
	// 					"alt": "first image"
	// 				},
	// 				"projectImages": [
	// 					{
	// 						"src": "/i/projects/tmrw/CKMS-Commercial-TMRW-03.jpg",
	// 						"alt": "",
	// 						"alignClass": "left_align_mod"
	// 					},
	// 					{
	// 						"src": "/i/projects/tmrw/CKMS-Commercial-TMRW-04.jpg",
	// 						"alt": "",
	// 						"alignClass": "center_align_mod"
	// 					},
	// 					{
	// 						"alignClass": "center_align_v1_mod",
	// 						"isSvg": true,
	// 						"src": "/i/CKMS-Commercial-TMRW-Plan.svg"
	// 					}
	// 				]
	// 			},
	// 			"neue-house-madison": {
	// 				"projectName": [
	// 					"2016 /",
	// 					"NEUE",
	// 					"HOUSE"
	// 				],
	// 				"projectDescription": "NeueHouse was one of the early pioneers of co-working, we were asked to design+build their headquarters as they outgrew their original location. This plan concept mediates between an open office plan and a domestic living room combining elements of each in such a way that blurs the boundary between the two. The objective was to capture an office typology that strips itself of the austerity of a working environment and can offer the comforts of the domestic.",
	// 				"mainProjectImage": {
	// 					"src": "/i/projects/nh-madison/CKMS-Commercial-Neue-House-01.jpg",
	// 					"alt": "main project image"
	// 				},
	// 				"firstProjectImage": {
	// 					"src": "/i/projects/nh-madison/CKMS-Commercial-Neue-House-02.jpg",
	// 					"alt": "first image"
	// 				},
	// 				"projectImages": [
	// 					{
	// 						"src": "/i/projects/nh-madison/CKMS-Commercial-Neue-House-03.jpg",
	// 						"alt": "",
	// 						"alignClass": "left_align_mod"
	// 					},
	// 					{
	// 						"alignClass": "center_align_v1_mod",
	// 						"isSvg": true,
	// 						"src": "/i/CKMS-Commercial-Neue-House-Plan.svg"
	// 					}
	// 				]
	// 			},
	// 			"neue-house-penthouse": {
	// 				"projectName": [
	// 					"2015 /",
	// 					"NEUE",
	// 					"HOUSE"
	// 				],
	// 				"projectDescription": "Built as an extension to existing NeueHouse Madison location.",
	// 				"mainProjectImage": {
	// 					"src": "/i/projects/nh-penthouse/CKMS-Commercial-Neue-House-Penthouse-01.jpg",
	// 					"alt": "main project image"
	// 				},
	// 				"firstProjectImage": {
	// 					"src": "/i/projects/nh-penthouse/CKMS-Commercial-Neue-House-Penthouse-02.jpg",
	// 					"alt": "first image"
	// 				},
	// 				"projectImages": [
	// 					{
	// 						"src": "/i/projects/nh-penthouse/CKMS-Commercial-Neue-House-Penthouse-03.jpg",
	// 						"alt": "",
	// 						"alignClass": "center_align_mod"
	// 					},
	// 					{
	// 						"alignClass": "center_align_v1_mod",
	// 						"isSvg": true,
	// 						"src": "/i/CKMS-Commercial-Neue-House-Penthouse-Plan.svg"
	// 					}
	// 				]
	// 			},
	// 			"bleeker-street": {
	// 				"projectName": [
	// 					"2018 /",
	// 					"59-17",
	// 					"BLEEKER"
	// 				],
	// 				"projectDescription": "This site is in a residential neighborhood flanked by lot line residential buildings on either side and a Public School at the rear. The massing circumvents local building envelope restrictions by mushrooming outwards as it rises and thus maximizing floor area. This move also allows daylight to more easily reach the lower levels of the building which is half submerged below ground level as well as providing a protective natural canopy along the access corridors. The primary building material is CMU blocks which are left exposed to the interior and coated with seamless and weather-proof stucco membrane on the exterior.",
	// 				"mainProjectImage": {
	// 					"src": "/i/projects/bleecker/CKMS-Residential-59-17-Bleecker-01.jpg",
	// 					"alt": "main project image"
	// 				},
	// 				"firstProjectImage": {
	// 					"src": "/i/projects/bleecker/CKMS-Residential-59-17-Bleecker-02.jpg",
	// 					"alt": "first image"
	// 				},
	// 				"projectImages": [
	// 					{
	// 						"src": "/i/projects/bleecker/CKMS-Residential-59-17-Bleecker-03.jpg",
	// 						"alt": "",
	// 						"alignClass": "left_align_mod"
	// 					},
	// 					{
	// 						"src": "/i/projects/bleecker/CKMS-Residential-59-17-Bleecker-04.jpg",
	// 						"alt": "",
	// 						"alignClass": "center_align_mod"
	// 					},
	// 					{
	// 						"alignClass": "center_align_v1_mod",
	// 						"isSvg": true,
	// 						"src": "/i/CKMS-Residential-59-17-Bleecker-Plan.svg"
	// 					}
	// 				]
	// 			},
	// 			"dstillery": {
	// 				"projectName": [
	// 					"2017 /",
	// 					"DSTILLERY"
	// 				],
	// 				"projectDescription": "We were asked to design+build a new headquarters for this tech startup. Using primarily baltic birch plywood and a cnc machine we designed custom furniture to a high degree of customization and on a very tight budget.",
	// 				"mainProjectImage": {
	// 					"src": "/i/projects/dstillery/CKMS-Commercial-Dstillery-01.jpg",
	// 					"alt": "main project image"
	// 				},
	// 				"firstProjectImage": {
	// 					"src": "/i/projects/dstillery/CKMS-Commercial-Dstillery-02.jpg",
	// 					"alt": "first image"
	// 				},
	// 				"projectImages": [
	// 					{
	// 						"src": "/i/projects/dstillery/CKMS-Commercial-Dstillery-03.jpg",
	// 						"alt": "",
	// 						"alignClass": "left_align_mod"
	// 					},
	// 					{
	// 						"alignClass": "center_align_v1_mod",
	// 						"isSvg": true,
	// 						"src": "/i/CKMS-Commercial-Dstillery-Plan.svg"
	// 					}
	// 				]
	// 			},
	// 			"ski-project": {
	// 				"projectName": [
	// 					"2018 /",
	// 					"SKI",
	// 					"PROJECT"
	// 				],
	// 				"projectDescription": "We were asked to design a pop-up store for this ski-wear clothing brand that could be freestanding, easily erected and transported and appear in a number of locations throughout Asia. Using mechanically connected steel frames, and shoji paper this project was conceived as a glowing lantern.",
	// 				"mainProjectImage": {
	// 					"src": "/i/projects/ski-project/CKMS-Commercial-Ski-Project-01.jpg",
	// 					"alt": "main project image"
	// 				},
	// 				"firstProjectImage": {
	// 					"src": "/i/projects/ski-project/CKMS-Commercial-Ski-Project-02.jpg",
	// 					"alt": "first image"
	// 				},
	// 				"projectImages": [
	// 					{
	// 						"src": "/i/projects/ski-project/CKMS-Commercial-Ski-Project-03.png",
	// 						"alt": "",
	// 						"alignClass": "center_align_mod"
	// 					},
	// 					{
	// 						"src": "/i/projects/ski-project/CKMS-Commercial-Ski-Project-04.jpg",
	// 						"alt": "",
	// 						"alignClass": "center_align_mod"
	// 					},
	// 					{
	// 						"alignClass": "center_align_v1_mod",
	// 						"isSvg": true,
	// 						"src": "/i/CKMS-Commercial-Ski-Project-Plan.svg"
	// 					}
	// 				]
	// 			},
	// 			"cross-fit": {
	// 				"projectName": [
	// 					"2018 /",
	// 					"CROSS",
	// 					"FIT"
	// 				],
	// 				"projectDescription": "At the height of crossfit mania we were asked to design+build a studio in a Brooklyn warehouse. Using industrial materials such as chipboard and rubber and then stripping away the dropped ceiling we created a functional and voluminous space optimized for exercise.",
	// 				"mainProjectImage": {
	// 					"src": "/i/projects/cross-fit/CKMS-Commercial-Cross-Fit-01.jpg",
	// 					"alt": "main project image"
	// 				},
	// 				"firstProjectImage": {
	// 					"src": "/i/projects/cross-fit/CKMS-Commercial-Cross-Fit-02.jpg",
	// 					"alt": "first image"
	// 				},
	// 				"projectImages": [
	// 					{
	// 						"alignClass": "column_mod",
	// 						"columns": [
	// 							{
	// 								"src": "/i/projects/cross-fit/CKMS-Commercial-Cross-Fit-03.jpg",
	// 								"alt": ""
	// 							},
	// 							{
	// 								"src": "/i/projects/cross-fit/CKMS-Commercial-Cross-Fit-04.jpg",
	// 								"alt": ""
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"src": "/i/projects/cross-fit/CKMS-Commercial-Cross-Fit-05.jpg",
	// 						"alt": "main project image",
	// 						"alignClass": "center_align_mod"
	// 					},
	// 					{
	// 						"alignClass": "center_align_v1_mod",
	// 						"isSvg": true,
	// 						"src": "/i/CKMS-Commercial-Cross-Fit-Plan.svg"
	// 					}
	// 				]
	// 			},
	// 			"sid-lee": {
	// 				"projectName": [
	// 					"2016 /",
	// 					"SID LEE"
	// 				],
	// 				"projectDescription": "This client was a creative agency seeking to expand their New York offices in TriBeCa, the space we inherited was a large, dark room surrounded by wood partitioned offices. We removed the existing wood partitions and recycled them into large worktables, transforming the communal area from a secluded room into an open and light-filled space. Glass partitions at the perimeter now enclose the offices and conference rooms while maintaining visual transparency and allowing natural light to permeate throughout the entire space.",
	// 				"mainProjectImage": {
	// 					"src": "/i/projects/sid-lee/CKMS-Commercial-Sid-Lee-01.jpg",
	// 					"alt": "main project image"
	// 				},
	// 				"firstProjectImage": {
	// 					"src": "/i/projects/sid-lee/CKMS-Commercial-Sid-Lee-02.png",
	// 					"alt": "first image"
	// 				},
	// 				"projectImages": [
	// 					{
	// 						"src": "/i/projects/sid-lee/CKMS-Commercial-Sid-Lee-03.png",
	// 						"alt": "",
	// 						"alignClass": "left_align_mod"
	// 					},
	// 					{
	// 						"alignClass": "center_align_v1_mod",
	// 						"isSvg": true,
	// 						"src": "/i/CKMS-Commercial-Sid-Lee-Plan.svg"
	// 					}
	// 				]
	// 			},
	// 			"greca": {
	// 				"projectName": [
	// 					"2017 /",
	// 					"GRECA"
	// 				],
	// 				"projectDescription": "This all day dining and lounge concept is an evolution of the nearby family owned and Michelin recommended restaurant \"The Greek”. The space we inherited was a raw shell with exposed pipes, ductwork and unfinished concrete, but with floor to ceiling glass. Our approach was to celebrate the existing roughness by revealing it further and to compliment it by introducing natural materials such as reclaimed yellow pine, leathers and abundance of plants.",
	// 				"mainProjectImage": {
	// 					"src": "/i/projects/greca/CKMS-Hospitality-Greca-01.jpg",
	// 					"alt": "main project image"
	// 				},
	// 				"firstProjectImage": {
	// 					"src": "/i/projects/greca/CKMS-Hospitality-Greca-02.jpg",
	// 					"alt": "first image"
	// 				},
	// 				"projectImages": [
	// 					{
	// 						"alignClass": "column_mod",
	// 						"columns": [
	// 							{
	// 								"src": "/i/projects/greca/CKMS-Hospitality-Greca-03.jpg",
	// 								"alt": ""
	// 							},
	// 							{
	// 								"src": "/i/projects/greca/CKMS-Hospitality-Greca-04.jpg",
	// 								"alt": ""
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"alignClass": "center_align_v1_mod",
	// 						"isSvg": true,
	// 						"src": "/i/CKMS-Hospitality-Greca-Plan.svg"
	// 					}
	// 				]
	// 			},
	// 			"cafe-roma": {
	// 				"projectName": [
	// 					"2017 /",
	// 					"CAFE",
	// 					"ROMA"
	// 				],
	// 				"projectDescription": "Cuba is an environment of scarce resources, for this project we used locally reclaimed materials salvaged from the surroundings and reappropriated them in order to construct this intervention. Nestled into a rooftop setback in the heart of Old Havana, once a golden era hotel from the 1950’s, this space was transformed from a living accommodation for a family of 3 into an open air rooftop bar. Using salvaged materials such as glass bricks, re-bar and marble slabs, we constructed a bar while the rest of the space was left in its original condition.",
	// 				"mainProjectImage": {
	// 					"src": "/i/projects/cafe-roma/CKMS-Hospitality-Cafe-Roma-01.jpg",
	// 					"alt": "main project image"
	// 				},
	// 				"firstProjectImage": {
	// 					"src": "/i/projects/cafe-roma/CKMS-Hospitality-Cafe-Roma-02.jpg",
	// 					"alt": "first image"
	// 				},
	// 				"projectImages": [
	// 					{
	// 						"src": "/i/projects/cafe-roma/CKMS-Hospitality-Cafe-Roma-03.jpg",
	// 						"alt": "",
	// 						"alignClass": "left_align_mod"
	// 					},
	// 					{
	// 						"alignClass": "center_align_v1_mod",
	// 						"isSvg": true,
	// 						"src": "/i/CKMS-Hospitality-Cafe-Roma-Plan.svg"
	// 					}
	// 				]
	// 			},
	// 			"tabula-rasa": {
	// 				"projectName": [
	// 					"2015 /",
	// 					"TABULA",
	// 					"RASA"
	// 				],
	// 				"projectDescription": "This is a concept for a modular housing complex. The architecture consists of a series of interlocking courtyard type modules that come together to form a continuous monolithic structure punctuated by courtyards. Loosely based on middle eastern courtyard housing typology this prototype has the ability to expand or contract in any direction in order to fit within any city plan, color coded corridors provide visual wayfinding in an otherwise undifferentiated plan.",
	// 				"mainProjectImage": {
	// 					"src": "/i/projects/al-naim/CKMS-Residential-Al-Naim-01.jpg",
	// 					"alt": "main project image"
	// 				},
	// 				"firstProjectImage": {
	// 					"src": "/i/projects/al-naim/CKMS-Residential-Al-Naim-02.jpg",
	// 					"alt": "first image"
	// 				},
	// 				"projectImages": [
	// 					{
	// 						"src": "/i/projects/al-naim/CKMS-Residential-Al-Naim-03.jpg",
	// 						"alt": "",
	// 						"alignClass": "left_align_mod"
	// 					},
	// 					{
	// 						"src": "/i/projects/al-naim/CKMS-Residential-Al-Naim-04.jpg",
	// 						"alt": "",
	// 						"alignClass": "center_align_mod"
	// 					},
	// 					{
	// 						"src": "/i/projects/al-naim/CKMS-Residential-Al-Naim-05.gif",
	// 						"alt": "",
	// 						"alignClass": "center_align_mod"
	// 					},
	// 					{
	// 						"alignClass": "center_align_v1_mod",
	// 						"isSvg": true,
	// 						"src": "/i/CKMS-Residential-Al-Naim-Plan.svg"
	// 					}
	// 				]
	// 			},
	// 			"folly-function": {
	// 				"projectName": [
	// 					"2017 /",
	// 					"FOLLY",
	// 					"FUNCTION"
	// 				],
	// 				"projectDescription": "The competition brief asks for 4 individual utilitarian shading structures. We proposed a kit-of-parts solution that comes together to form a multitude of configurations with varying degrees of utility, ranging from purely utilitarian to purely sculptural.",
	// 				"mainProjectImage": {
	// 					"src": "/i/projects/folly-function/CKMS-Installation-Folly-Function-01.jpg",
	// 					"alt": "main project image"
	// 				},
	// 				"firstProjectImage": {
	// 					"src": "/i/projects/folly-function/CKMS-Installation-Folly-Function-02.jpg",
	// 					"alt": "first image"
	// 				},
	// 				"projectImages": [
	// 					{
	// 						"src": "/i/projects/folly-function/CKMS-Installation-Folly-Function-03.gif",
	// 						"alt": "",
	// 						"alignClass": "center_align_mod"
	// 					},
	// 					{
	// 						"src": "/i/projects/folly-function/CKMS-Installation-Folly-Function-04.jpg",
	// 						"alt": "",
	// 						"alignClass": "center_align_mod"
	// 					}
	// 				]
	// 			}
	// 		}
	// 	});
	// }, []);

	useEffect(() => {
		document.body.classList.add('loaded_mod');
	}, []);

	return (
		jsonData ? (
			<div className="wrapper hidden_mod">
				{windowWidth > MOBILE_MEDIA_POINT && <Header ref={headerEl} />}
				{windowWidth > TABLET_MEDIA_POINT && <Cursor ref={cursorEl} />}
				{windowWidth <= MOBILE_MEDIA_POINT && <Menu />}
				<div className="base">
					<TransitionGroup>
						<Transition
							key={location.pathname}
							timeout={1200}
							onExit={onExit}
							onEntering={onEnter}
							mountOnEnter
							unmountOnExit
						>
							<Switch location={location}>
								<Route path="/" component={PAGES.HomePage} exact />
								<Route path="/contact" component={PAGES.ContactPage} />
								<Route path="/design" component={PAGES.DesignPage} />
								<Route path="/projects/:name" component={PAGES.ProjectsPage} />
								<Route component={PAGES.NotFound} />
							</Switch>
						</Transition>
					</TransitionGroup>
				</div>
				<Footer ref={footerEl} />
			</div>
		) : null
	);
}

export default App;
